import gratesandflows from 'src/assets/grates.json';


export class Grates {

    constructor() {
    }

    public getFlow(ponding: number, surfaceRunoff: number, grateRequirement: string) {
        let pondingPosition: number = ponding / 0.5;
        let flowDifference: number = 999;
        let smallestFlowPosition: number = 0;
       
        if (grateRequirement == "green") {
            for (var i = 0; i < gratesandflows.length; i++) {
                if (Math.abs(gratesandflows[i].Flows[pondingPosition] - surfaceRunoff) <= flowDifference && gratesandflows[i].Flows[pondingPosition] >= surfaceRunoff) {
                    flowDifference = Math.abs(gratesandflows[i].Flows[pondingPosition] - surfaceRunoff);
                    smallestFlowPosition = i;
                }
            }
        }


        if (grateRequirement == "ada") {
            for (var i = 0; i < gratesandflows.length; i++) {
                if (gratesandflows[i].ADA == true) {
                    if (Math.abs(gratesandflows[i].Flows[pondingPosition] - surfaceRunoff) <= flowDifference && gratesandflows[i].Flows[pondingPosition] >= surfaceRunoff) {
                        flowDifference = Math.abs(gratesandflows[i].Flows[pondingPosition] - surfaceRunoff);
                        smallestFlowPosition = i;
                    }
                }
            }
        }
        if (grateRequirement == "aashto") {
            for (var i = 0; i < gratesandflows.length; i++) {
                if (gratesandflows[i].AASHTO == true) {

                    if (Math.abs(gratesandflows[i].Flows[pondingPosition] - surfaceRunoff) <= flowDifference && gratesandflows[i].Flows[pondingPosition] >= surfaceRunoff) {
                        flowDifference = Math.abs(gratesandflows[i].Flows[pondingPosition] - surfaceRunoff);
                        smallestFlowPosition = i;
                    }
                }
            }
        }

        let minFlow = gratesandflows[0].Flows[pondingPosition];
        let maxFlow = gratesandflows[13].Flows[pondingPosition];
        if (surfaceRunoff < minFlow || surfaceRunoff > maxFlow) {
            let grateInfo: any = {
                "GrateSize": "Change Design Parameters",
                "Flow": 0
            };
            return grateInfo;
        }
        else {
            
            let grateInfo: any = {
                "GrateSize": gratesandflows[smallestFlowPosition].GrateSize,
                "Flow": gratesandflows[smallestFlowPosition].Flows[pondingPosition]
            };
            return grateInfo;
        }
    }
}