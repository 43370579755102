import USRegions from 'src/assets/USRegions.json';


export class Region {
    ID: number;

    constructor(regionValue: number ) {
        this.ID = regionValue;
        
    }

    public ReturnK(stormFrequency: number): number {
       
        switch (stormFrequency.toString()) {
            case "2":
                return USRegions[this.ID - 1].Return2k;
            case "5":
                return USRegions[this.ID - 1].Return5k;
            case "10":
                return USRegions[this.ID - 1].Return10k;
            case "25":
                return USRegions[this.ID - 1].Return25k;
            case "50":
                return USRegions[this.ID - 1].Return50k;
            case "100":
                return USRegions[this.ID - 1].Return100k;
        }

        return 0;
    }

    public ReturnB(stormFrequency: number): number {

        switch (stormFrequency.toString()) {
            case "2":
                return USRegions[this.ID - 1].Return2b;
            case "5":
                return USRegions[this.ID - 1].Return5b;
            case "10":
                return USRegions[this.ID - 1].Return10b;
            case "25":
                return USRegions[this.ID - 1].Return25b;
            case "50":
                return USRegions[this.ID - 1].Return50b;
            case "100":
                return USRegions[this.ID - 1].Return100b;
        }

        return 0;
    }

}



 