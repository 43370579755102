<header>
  <div class="container">
      <nav class="navbar navbar-expand-md navbar-dark">




          <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" [collapse]="isCollapsed" id="navbar">

              <div class="container d-flex flex-column flex-md-row justify-content-end">
                  <a href="https://www.nyloplast-us.com/" class="pr-4">
                      <i class="fa fa-home" aria-hidden="true"></i>
                  </a>

              </div>

          </div>
      </nav>
  </div>
</header>

<main role="main">
  <router-outlet></router-outlet>
</main>

<footer>
    <div class="container">

        <div class="row">

            <div class="col-md-6 order-last order-md-first my-auto">
                <a href="https://www.adspipe.com/"><img id="advlogo" src="../assets/images/Large-ADS Full Color Black Logo (RGB).png" style="width: 140px;" class="mt-2 mb-2" /></a>
                <br />
                <a href="https://www.adspipe.com/privacy-policy" target="_blank" style="color: #747474;">Privacy Policy</a>
                <br />
                <a href="http://www.adspipe.com/us-patents" target="_blank" style="color: #747474;">US Patents</a>
                <br />
                <a href="https://www.adspipe.com/legal" target="_blank" style="color: #747474;">Legal</a>
                <br />
                <a href="https://www.adspipe.com/ada-compliance" target="_blank" style="color: #747474;">ADA Compliance</a>
                <br />
                <p class="mt-2 mb-2">&copy; {{ currentDate | date:'y' }} Advanced Drainage Systems &trade; All rights reserved.</p>
            </div>


            <div class="col-md-6 pt-2 pb-3">

                <h4>Nyloplast</h4>
                <p>
                    3130 Verona Ave.<br />
                    Buford, GA 30518<br />
                    <br />

                    <a href="https://www.nyloplast-us.com/contact-us">Contact Us</a><br />
                    Need Project Support? (866) 888-8479 <br />
                    Fax: (678) 244-0034
                </p>
                <a href="#" onclick="{Osano.cm.showDrawer()}">Manage My Cookie Preferences</a><br />

                <a href="#" onclick="{Osano.cm.showDoNotSell()};">Do Not Sell My Information</a><br />
            </div>

        </div>
    </div>
</footer>
