import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
//import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-error403',
  templateUrl: './error403.component.html',
})
export class Error403PageComponent implements OnInit {

  error = '';

  constructor(
              private router: Router,
              private activatedRoute: ActivatedRoute
  ) {
  }

  
  goHome(): void {
    this.router.navigate(['/']);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params && params.error) {
        this.error = params.error;
      }
    });
  }
}
