<div class="jumbotron">
    <div class="container">

        <div class="navbar-brand ml-1">
            <img src="./assets/images/Nyloplast_Logo_FullColor_White_RGB.png" style="height: 40px;" alt="ads logo">
        </div>
       
        <h1>Grate Inlet Calculator</h1>
        <span>Nyloplast Grate Inlet Size & Type Calculator Based on Peak Flow of Site Design</span>
    </div>
</div>
<div class="container">
    <form [formGroup]="form" novalidate>
        <div class="row">
            <div class="col-md-6">



                <div class="d-flex">
                    <h5>Drainage Area Design Parameters</h5>
                    <div class="ml-auto">
                        <div class="form-group" *ngIf="!showAreaConversionTool">
                            <button type="button" class="btn btn-link" (click)="showAreaConversionTool = true">Area Conversion Tool <i class="fa fa-plus-square"></i></button>
                        </div>
                        <div class="form-group" *ngIf="showAreaConversionTool">
                            <button type="button" class="btn btn-link" (click)="showAreaConversionTool = false">Area Conversion Tool <i class="fa fa-minus-square"></i></button>
                        </div>
                    </div>
                </div>
                <div *ngIf="showAreaConversionTool" [@enterAnimation]>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-sm-4">
                            </div>
                            <label for="inputAreaSqFt" class="col-sm-4 col-form-label">Square Feet</label>
                            <div class="col-sm-4">
                                <div class="input-group-narrow">
                                    <input class="form-control" id="inputSquareFoot" formControlName="squareFoot" />
                                </div>
                            </div>
                            <div class="col-sm-4">
                            </div>
                            <label for="inputAcres" class="col-sm-4 col-form-label">Acres</label>
                            <div class="col-sm-4">
                                <div class="input-group-narrow">
                                    <span class="form-control" formControlName="acreConverted">{{ getAreaConverted() }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="form-group">
                    <div class="row">
                        <label for="inputArea1Acre" class=" col-sm-4 col-form-label">Area #1 (ac.)</label>
                        <div class="col-sm-8">
                            <div class="input-group narrow">
                                <input type="number" inputmode="number" step=".1" class="form-control" id="inputArea1Acre" placeholder="enter unit" formControlName="area1Acre" min="0" max="100" required>
                                <div class="input-group-postpend">
                                    <span class="input-group-text" id="inputArea1Acre">ac.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputAreaType1" class="col-sm-4 col-form-label">Area #1 Type</label>

                        <div class="col-sm-8">
                            <select class="custom-select" formControlName="areaType1">
                                <option value="1">Concrete/Asphalt/Roof</option>
                                <option value="0.7">Compacted Gravel</option>
                                <option value="0.75">Clay - Bare</option>
                                <option value="0.6">Clay w/ Light Vegetation</option>
                                <option value="0.5">Clay w/ Dense Vegetation</option>
                                <option value="0.6">Gravel w/ Light Vegetation</option>
                                <option value="0.4">Gravel w/ Dense Vegetation</option>
                                <option value="0.6">Loam - Bare</option>
                                <option value="0.45">Loam w/ Light Vegetation</option>
                                <option value="0.35">Loam w/ Dense Vegetation</option>
                                <option value="0.5">Bare Sand</option>
                                <option value="0.4">Sand w/ Light Vegetation</option>
                                <option value="0.3">Sand w/ Dense Vegetation</option>
                                <option value="0.35">Grass Area</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputArea2Acre" class=" col-sm-4 col-form-label">Area #2 (ac.)</label>
                        <div class="col-sm-8">
                            <div class="input-group narrow">
                                <input type="number" inputmode="number" step=".1" class="form-control" id="inputArea3Acre" placeholder="enter unit" formControlName="area2Acre" min="0" max="100" required>
                                <div class="input-group-postpend">
                                    <span class="input-group-text" id="inputArea2Acre">ac.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">

                        <label for="inputAreaType2" class="col-sm-4 col-form-label">Area #2 Type</label>
                        <div class="col-sm-8">
                            <select class="custom-select" formControlName="areaType2">
                                <option value="1">Concrete/Asphalt/Roof</option>
                                <option value="0.7">Compacted Gravel</option>
                                <option value="0.75">Clay - Bare</option>
                                <option value="0.6">Clay w/ Light Vegetation</option>
                                <option value="0.5">Clay w/ Dense Vegetation</option>
                                <option value="0.6">Gravel w/ Light Vegetation</option>
                                <option value="0.4">Gravel w/ Dense Vegetation</option>
                                <option value="0.6">Loam - Bare</option>
                                <option value="0.45">Loam w/ Light Vegetation</option>
                                <option value="0.35">Loam w/ Dense Vegetation</option>
                                <option value="0.5">Bare Sand</option>
                                <option value="0.4">Sand w/ Light Vegetation</option>
                                <option value="0.3">Sand w/ Dense Vegetation</option>
                                <option value="0.35">Grass Area</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputArea3Acre" class=" col-sm-4 col-form-label">Area #3 (ac.)</label>
                        <div class="col-sm-8">
                            <div class="input-group narrow">
                                <input type="number" inputmode="number" step=".1" class="form-control" id="inputArea3Acre" placeholder="enter unit" formControlName="area3Acre" min="0" max="100" required>
                                <div class="input-group-postpend">
                                    <span class="input-group-text" id="inputArea3Acre">ac.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputAreaType3" class="col-sm-4 col-form-label">Area #3 Type</label>
                        <div class="col-sm-8">
                            <select class="custom-select" formControlName="areaType3">
                                <option value="1">Concrete/Asphalt/Roof</option>
                                <option value="0.7">Compacted Gravel</option>
                                <option value="0.75">Clay - Bare</option>
                                <option value="0.6">Clay w/ Light Vegetation</option>
                                <option value="0.5">Clay w/ Dense Vegetation</option>
                                <option value="0.6">Gravel w/ Light Vegetation</option>
                                <option value="0.4">Gravel w/ Dense Vegetation</option>
                                <option value="0.6">Loam - Bare</option>
                                <option value="0.45">Loam w/ Light Vegetation</option>
                                <option value="0.35">Loam w/ Dense Vegetation</option>
                                <option value="0.5">Bare Sand</option>
                                <option value="0.4">Sand w/ Light Vegetation</option>
                                <option value="0.3">Sand w/ Dense Vegetation</option>
                                <option value="0.35">Grass Area</option>
                            </select>
                        </div>
                    </div>
                </div>


                <h5>Peak Runoff Flow Parameters</h5>
                <div class="form-group">
                    <div class="row">
                        <label for="inputRunoffCoefficient" class=" col-sm-4 col-form-label">U.S Region (use map)</label>
                        <div class="col-sm-8">
                            <select class="custom-select" formControlName="usRegion">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputRunoffCoefficient" class=" col-sm-4 col-form-label">Runoff Coefficient</label>
                        <div class="col-sm-8">
                            <div class="input-group narrow">
                                <span class="form-control">{{ getRunoffCoefficient() }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputStormFrequency" class="col-sm-4 col-form-label">Storm Event Frequency (yrs)</label>
                        <div class="col-sm-8">
                            <select class="custom-select" formControlName="stormFrequency">
                                <option value=2>2</option>
                                <option value=5>5</option>
                                <option value=10>10</option>
                                <option value=25>25</option>
                                <option value=50>50</option>
                                <option value=100>100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputStormDuration" class="col-sm-4 col-form-label">Storm Duration</label>
                        <div class="col-sm-8">
                            <select class="custom-select" formControlName="stormDuration">
                                <option value=5>5 min.</option>
                                <option value=10>10 min.</option>
                                <option value=30>30 min.</option>
                                <option value=60>1 hr.</option>
                                <option value=120>2 hr.</option>
                                <option value=180>3 hr.</option>
                                <option value=360>6 hr.</option>
                                <option value=720>12 hr.</option>
                                <option value=1440>24 hr.</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputRainfallIntensity" class=" col-sm-4 col-form-label">Rainfall Intensity in/hr</label>
                        <div class="col-sm-8">
                            <div class="input-group narrow">
                                <span class="form-control">{{ getRainfallIntensity() }}</span>
                                <div class="input-group-postpend">
                                    <span class="input-group-text" id="inputRainfallIntensity">in/hr</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h5>Peak Flow Output</h5>
                <div class="form-group">
                    <div class="row">
                        <label for="inputPeakRunoffCFS" class=" col-sm-4 col-form-label">Peak Surface Runoff (cfs)</label>
                        <div class="col-sm-8">
                            <div class="input-group narrow">
                                <span class="form-control">{{ getPeakRunoffCFS() }} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputPeakRunoffGPM" class=" col-sm-4 col-form-label">Peak Surface Runoff (gpm)</label>
                        <div class="col-sm-8">
                            <div class="input-group narrow">
                                <span class="form-control">{{ getPeakRunoffGPM() }}</span>

                            </div>
                        </div>
                    </div>
                </div>

            </div> <!-- col-md-6 -->

            <div class="col-md-6">
                <h5>Grate Inlet Capacity Input</h5>
                <br />
                <div class="form-group">
                    <div class="row">
                        <label for="inputAllowablePonding" class=" col-sm-4 col-form-label">Allowable Ponding Depth Above Grate (in)</label>
                        <div class="col-sm-8">
                            <div class="input-group narrow">
                                <input type="number" inputmode="number" step=".01" class="form-control" id="inputAllowablePonding" formControlName="allowablePonding" min="0" max="100" required>
                                <span class="input-group-text" id="inputAllowablePonding">in</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label for="inputGrateRequirements" class=" col-sm-4 col-form-label">Grate Requirements</label>
                        <div class="col-sm-8">
                            <select class="custom-select" formControlName="grateRequirements">
                                <option value="ada">ADA Compliant</option>
                                <option value="aashto">AASHTO H-20</option>
                                <option value="green">Green Space/Landscape</option>
                            </select>
                        </div>
                    </div>
                </div>
                <ng-container>
                    <h5>Minimum Recommended Grate Inlet Type & Size</h5>
                    <table class="table">


                        <tr>
                            <th>Grate Style</th>
                            <td>{{ getMinimumGrate() }}</td>
                        </tr>
                        <tr>
                            <th>Inlet Capacity (cfs)</th>
                            <td>{{ getInletCapacity() }}</td>
                        </tr>
                        <tr>
                            <th>Inlet Capacity (gpm)</th>
                            <td>{{ getInletCapacityGPM() }}</td>
                        </tr>
                    </table>
                </ng-container>
                <img src="./assets/images/US Regions.png" />
            </div><!-- col-md-6 -->
        </div><!-- row -->
    </form>
</div><!-- container -->