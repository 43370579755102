import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { toUnicode } from 'punycode';
import { HandleError, HttpErrorHandler } from 'src/app/infrastructure/http-error-handler.service';

@Injectable()
export class ConfiguratorService {
    private handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('ConfiguratorService');
    }

    configure(input: any): Observable<any> {
        return this.http.post<any>('api/Configurator/Configure', input)
            .pipe(
                catchError(this.handleError('getResponse', []))
            );
    }

    

}
