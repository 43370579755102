<div class="jumbotron">
  <div class="container">
    <h1>Unauthorized</h1>
  </div>
</div>

<div class="container">
  <div class="row">
    <p>You do not have permission to access the requested resource.</p>
  </div>
</div>
