import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
//import { AdalService } from 'adal-angular4';
import { ProgressBarService } from './infrastructure/progress-bar.service'; declare const Modernizr;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    currentDate: Date;
    progressBarActive = false;
    isCollapsed = true;

    /*private adalConfig = {
        tenant: 'b38e8338-5d36-48f8-8ea4-18cc0ece15ef',
        clientId: 'bdb03652-7852-4d0a-bf89-0b86b2c24c27',
        extraQueryParameter: 'domain_hint=ads-pipe.com',
        redirectUri: this.baseUrl + 'authorize',
        cacheLocation: 'localStorage',
        postLogoutRedirectUri: 'https://www.ads-pipe.com',
        anonymousEndpoints: []
    };
    */
    constructor(
                private router: Router,
                private http: HttpClient,
                private progressBarService: ProgressBarService,
                private changeDetector: ChangeDetectorRef,
                @Inject('BASE_URL') private baseUrl: string) { }

    ngOnInit() {
        this.currentDate = new Date();

        // authentication
        //this.adal.init(this.adalConfig);

        this.progressBarService.updateProgressBar$.subscribe((isActive: boolean) => {
            this.progressBarActive = isActive;
            this.changeDetector.detectChanges();
        });
    }

    checkBrowserFeatures() {
        let supported = true;
        for (const feature in Modernizr) {
            if (Modernizr.hasOwnProperty(feature) &&
                typeof Modernizr[feature] === 'boolean' && Modernizr[feature] === false) {
                supported = false;
                break;
            }
        }

        if (!supported) {
            // write message
        }

        return supported;
    }

}
