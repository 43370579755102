<div class="jumbotron">
  <div class="container">
    <h1>Error</h1>
    <p>An unknown error has occurred.</p>
  </div>
</div>


<div class="container">
  <div class="row">
    <p>Please try again.</p>
  </div>
</div>
