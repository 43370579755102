<div class="jumbotron">
  <div class="container">
    <h1>Page not found</h1>
  </div>
</div>

<div class="container">
  <div class="row">
    <p>Sorry, we can’t find the page you were looking for.</p>
  </div>
</div>
