import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
//import { AdalInterceptor, AdalService } from 'adal-angular4';
//import { AuthCallbackComponent } from './infrastructure/auth-callback.component';
//import { AuthGuard } from './infrastructure/auth-guard.service';

/* Global Services */
import { HttpErrorHandler } from './infrastructure/http-error-handler.service';
import { ProgressBarService } from './infrastructure/progress-bar.service';
import { CollapseModule, ModalModule, ButtonsModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { QuillModule } from 'ngx-quill';
import { NgSelectModule } from '@ng-select/ng-select';
import { SanitizeHtmlPipe } from './infrastructure/sanitize-html.pipe';

/* Pages */
import { ErrorPageComponent } from './pages/shared/error.component';
import { Error403PageComponent } from './pages/shared/error403.component';
import { Error404PageComponent } from './pages/shared/error404.component';
import { HomePageComponent } from './pages/home/home.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [
        AppComponent, 
        ErrorPageComponent, Error403PageComponent, Error404PageComponent,
        HomePageComponent,
        SanitizeHtmlPipe
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        AlertModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TabsModule.forRoot(),
        PaginationModule.forRoot(),
        ButtonsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        QuillModule.forRoot(),
        NgSelectModule,
       
        RouterModule.forRoot([
            { path: '', component: HomePageComponent, runGuardsAndResolvers: 'always' },
            { path: 'error', component: ErrorPageComponent },
            { path: 'error404', component: Error404PageComponent },
            { path: 'error403', component: Error403PageComponent },
            { path: '**', redirectTo: '/error404' }
        ], { onSameUrlNavigation: 'reload' }),

        FontAwesomeModule
    ],
    providers: [
        HttpErrorHandler,
        ProgressBarService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
