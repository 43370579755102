import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import USRegions from 'src/assets/USRegions.json';
import * as coefficients from 'src/assets/coefficients.json';
import { Region } from '../../models/region.model';
import { Grates } from '../../models/grates.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    providers: [ConfiguratorService],
    animations: [
        trigger(
            'leaveAnimation', [
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('500ms ease-in-out', style({ 'opacity': '0' }))
            ])
        ]
        ),
        trigger(
            'enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(0)', opacity: 0 }),
                animate('500ms ease-in-out', style({ 'opacity': '1' }))
            ])
        ]
        ),
        trigger(
            'enterLeaveAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(0)', opacity: 0 }),
                animate('500ms ease-in-out', style({ 'opacity': '1' }))
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('200ms ease-in-out', style({ 'opacity': '0' }))
            ])
        ]
        )
    ]
})


export class HomePageComponent implements OnInit {

    form: FormGroup;
    response: any;
    isLoading: boolean = false; 
    serviceDebounceTimeout: any = null;
    showAreaConversionTool: boolean = false;
    showRecommendationInlet: boolean = false;
    modal
    modalUserAgree: BsModalRef;

    availableForms: any = [];

    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private configuratorService: ConfiguratorService,
        private modalService: BsModalService) { }
    ngOnInit() {

        this.form = this.formBuilder.group({
            squareFoot: null,
            acreConverted: null,
            area1Acre: null,
            area2Acre: null,
            area3Acre: null,
            areaType1: [''],
            areaType2: [''],
            areaType3: [''],
            usRegion: null,
            allowablePonding: null,
            runoffCoefficient: 0,
            stormFrequency: null,
            stormDuration: null,
            rainfallIntensity: 0,
            peakRunoffCFS: 0,
            peakRunoffGPM: 0,
            grateRequirements: null,
        });
    }

    get f() { return this.form.controls; }


    getAreaConverted() {
        if (this.form.controls.squareFoot.value != null) {
            let acreConverted: number = 0.000023 * this.form.controls['squareFoot'].value;
            return acreConverted;
        }
    }

    getRunoffCoefficient(): any {
        if (this.form.controls['areaType1'].value && this.form.controls['area1Acre'].value != null) {
            let area1Coefficient: number = this.form.controls['areaType1'].value * this.form.controls['area1Acre'].value;
            let area2Coefficient: number = this.form.controls['areaType2'].value * this.form.controls['area2Acre'].value;
            let area3Coefficient: number = this.form.controls['areaType3'].value * this.form.controls['area3Acre'].value;
            let areaCoefficient: number = area1Coefficient + area2Coefficient + area3Coefficient

            let acreageCombine: number = this.form.controls['area1Acre'].value + this.form.controls['area2Acre'].value + this.form.controls['area3Acre'].value;

            let runoffCoefficient: number = areaCoefficient / acreageCombine;

            return runoffCoefficient.toFixed(2);
        }
    }

    getRainfallIntensity() {

        if (this.form.controls.usRegion.value && this.form.controls.stormFrequency.value && this.form.controls.stormDuration.value != null) {
            let regionValue: number = this.form.controls.usRegion.value;
            let region: Region = new Region(regionValue);
            let stormFrequency: number = this.form.controls.stormFrequency.value;
            let stormDuration: number = parseInt(this.form.controls.stormDuration.value);
            let tbCoefficients: number = (region.ReturnB(stormFrequency)) + (stormDuration);
            let kCoefficient: number = region.ReturnK(stormFrequency);
            let rainfallIntensity: number = kCoefficient / tbCoefficients;
            return rainfallIntensity.toFixed(2);

        } else { return null; }
    }

    getPeakRunoffCFS() {
        if (this.form.controls['area1Acre'].value && this.form.controls['areaType1'].value && this.getRainfallIntensity() != null) {
            let acreageCombine: number = this.form.controls['area1Acre'].value + this.form.controls['area2Acre'].value + this.form.controls['area3Acre'].value;
            let peakRunoffCFS: number = acreageCombine * this.getRunoffCoefficient() * parseFloat(this.getRainfallIntensity());
             
            return peakRunoffCFS.toFixed(5);
        }
    }

    getPeakRunoffGPM() {
        if (this.getPeakRunoffCFS() != null) {
            let peakRunoffGPM: number = 448.8325660485 * parseFloat(this.getPeakRunoffCFS());
            return peakRunoffGPM.toFixed(5);
        }
    }

    getInletCapacity() {
        if (this.form.controls.allowablePonding.value && this.form.controls.grateRequirements.value != null) {
            let grates: Grates = new Grates();
            let surfaceRunoff: number = parseFloat(this.getPeakRunoffCFS());
            let ponding: number = this.form.controls.allowablePonding.value;
            let grateRequirement: string = this.form.controls.grateRequirements.value;
            let inletCapacity: any = grates.getFlow(ponding, surfaceRunoff, grateRequirement);
           
            return inletCapacity.Flow.toFixed(5);

            
        }
    }

    getMinimumGrate() {
        if (this.getInletCapacity() != null) {
            let grates: Grates = new Grates();
            let surfaceRunoff: number = parseFloat(this.getPeakRunoffCFS());
            let ponding: number = this.form.controls.allowablePonding.value;
            let grateRequirement: string = this.form.controls.grateRequirements.value;
            let grateRecommendation: any = grates.getFlow(ponding, surfaceRunoff, grateRequirement);

            return grateRecommendation.GrateSize;
        }
    }

    getInletCapacityGPM() {
        if (this.getInletCapacity() != null) {
            let inletCapacityGPM: number = 448.8325660485 * this.getInletCapacity();
            return inletCapacityGPM.toFixed(2);
        }
    }

    onAgreeToUserAgreement() {
        localStorage.setItem("UserAgreement", "1");
        this.modalUserAgree.hide();
       
    }
    

}

