import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorPageComponent implements OnInit {

  error: any = {};

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {


  }
}
