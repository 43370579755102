import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
    <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
    constructor(private router: Router, private ngZone: NgZone) { }

    /** Create curried handleError function that already knows the service name */
    createHandleError = (serviceName = '') => <T>
        (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result)

    /**
     * Returns a function that handles Http operation failures.
     * This error handler lets the app continue to run as if no error occurred.
     * @param serviceName = name of the data service that attempted the operation
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {

        return (error: HttpErrorResponse): Observable<T> => {

            if (!navigator.onLine) {
                // add message
            }

            if (error.error instanceof Blob || error.status >= 500) {
                // a critical error occurred, navigate pages
                this.router.navigate(['/error'], { queryParams: { error } });
            }

            if (error.status === 404) {
                this.router.navigate(['/error404']);
            }
            if (error.status === 403) {
                this.router.navigate(['/error403']);
            }

            if (error.status === 400 && error.error) { // this is an error the page can handle
                if (error.error && error.error.length > 0) {
                    for (const err of error.error) {
                        // add error
                    }
                }
            }

            // console.error(error); // log to console instead

            // Let the app keep running by returning a safe result.
            // return of(result);

            return throwError(error);
        };

    }
}
